function openUrl(ele) {
    let url = $(ele).data('url');
    let isDirect = $(ele).data('direct');
    let id = $(ele).data('id');
    let queryString = isDirect ? '0' : '1';
    url += `?form-search=${queryString}`;

    if (isDirect) {
        window.location.href = url;
    } else {
        let w = (parseInt(window.innerWidth) * .9);
        let h = (parseInt(window.innerHeight) * .8);
        let left = (screen.width / 2) - (w / 2);
        let top = (screen.height / 2) - (h / 2);
        return window.open(url, id, 'toolbar=no, location=no, directories=no, status=no, menubar=no, ' +
            'scrollbars=no, resizable=no, copyhistory=no, width=' + w + ',height=' + h + ', top=' + top + ', left=' + left + ';');
    }
}
